import { createContext } from "react";
import { initialAuthState } from "auth/initialAuthState";

const stub = () => {
  throw new Error("You forgot to wrap your component in <ServiceAuthProvider>");
};

const initialAuthContext = {
  ...initialAuthState,
  redirectToServiceauth: stub,
  getAccessToken: stub,
  logout: stub,
};

const ServiceAuthContext = createContext(initialAuthContext);

export default ServiceAuthContext;
