import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

// used to indicate that another component is still processing
const Loading = () => {
  return (
    <div className="my-4">
      <div className="d-flex flex-row justify-content-center">
        <h2>Loading...</h2>
      </div>
      <div className="d-flex flex-row justify-content-center">
        <FontAwesomeIcon icon={faCog} size="5x" spin />
      </div>
    </div>
  );
};

export default Loading;
