// @ts-check

// React
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// pages
import Home from "pages/Home";
import Data from "pages/Data";
import Login from "pages/Login";

// components
import Header from "components/Header";
import Footer from "components/Footer";

// authorization
import ServiceAuthRoute from "auth/ServiceAuthRoute";
import ServiceAuthProvider from "contexts_and_providers/ServiceAuth/ServiceAuthProvider";

// providers
import DataTableProvider from "contexts_and_providers/DataTable/DataTableProvider";

// style sheets
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <ServiceAuthProvider>
          <DataTableProvider>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/data"
                element={
                  <ServiceAuthRoute>
                    <Data />
                  </ServiceAuthRoute>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
            <Footer />
          </DataTableProvider>
        </ServiceAuthProvider>
      </Router>
    </div>
  );
}

export default App;
