import React, { useState } from "react";
import DataTableContext from "./DataTableContext";

const DataTableProvider = ({ children }) => {
  const [changed, setChanged] = useState(false);

  const wasChanged = async () => {
    setChanged(true);
  };

  const doneWithChange = async () => {
    setChanged(false);
  };

  const value = {
    changed,
    applyChange: wasChanged,
    finishChange: doneWithChange,
  };

  return (
    <DataTableContext.Provider value={value}>
      {children}
    </DataTableContext.Provider>
  );
};

export default DataTableProvider;
