import React from "react";
import { Hero } from "@asu/components-core/dist/libCore.es";
import AddForm from "components/AddForm";
import DataTable from "components/DataTable";
import DeleteForm from "components/DeleteForm";

const Data = () => {
  return (
    <div>
      <Hero
        contentsColor="white"
        image={{
          altText: "Hero image",
          size: "small",
          url: "https://source.unsplash.com/WLUHO9A_xik/800x400?a=1",
        }}
        title={{
          color: "white",
          text: "Data",
        }}
      />

      <br />
      <AddForm />
      <br />
      <DeleteForm />
      <br />
      <DataTable />
    </div>
  );
};

export default Data;
