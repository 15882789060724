import React from "react";
import { Hero } from "@asu/components-core/dist/libCore.es";

const Home = () => {
  return (
    <div>
      <Hero
        contentsColor="white"
        image={{
          altText: "Hero image",
          size: "small",
          url: "https://source.unsplash.com/WLUHO9A_xik/800x400?a=1",
        }}
        title={{
          color: "white",
          text: "Home",
        }}
      />

      <div className="container">
        <h3>
          This is the landing page. Authorization is not required to be here. To
          access the Data page, you must be logged in with your ASUrite.
        </h3>
      </div>

      {/* For later use */}
      {/* <div className="container">
        <div className="row row-spaced pt-2 pb-2">
          <div className="col col-12 col-md-6 col-lg-4">
            <Card
              title="Card 1"
              image="https://source.unsplash.com/WLUHO9A_xik/300x200"
              imageAltText="An example image"
              body="<span style='font-weight: bold;'>TBD</span> Coming soon..."
              buttons={[
                {
                  color: "maroon",
                  label: "CTA button",
                  size: "small",
                },
                {
                  color: "gold",
                  href: "/",
                  label: "Link Button",
                  size: "small",
                  target: "_top",
                },
              ]}
            />
          </div>
          <div className="col col-12 col-md-6 col-lg-4">
            <Card
              key={2}
              body="<span style='font-weight: bold;'>TBD</span> Coming soon..."
              buttons={[
                {
                  color: "maroon",
                  label: "CTA button",
                  size: "small",
                },
                {
                  color: "gold",
                  href: "/",
                  label: "Link Button",
                  size: "small",
                  target: "_top",
                },
              ]}
              image="https://source.unsplash.com/WLUHO9A_xik/300x200"
              imageAltText="An example image"
              title="Card 2"
            />
          </div>
          <div className="col col-12 col-md-6 col-lg-4">
            <Card
              key={3}
              body="<span style='font-weight: bold;'>TBD</span> Coming soon..."
              buttons={[
                {
                  color: "maroon",
                  label: "CTA button",
                  size: "small",
                },
                {
                  color: "gold",
                  href: "/",
                  label: "Link Button",
                  size: "small",
                  target: "_top",
                },
              ]}
              image="https://source.unsplash.com/WLUHO9A_xik/300x200"
              imageAltText="An example image"
              tags={[
                {
                  color: "gray",
                  href: "/#example-link",
                  label: "tag1",
                },
                {
                  color: "gray",
                  href: "/#example-link",
                  label: "tag2",
                },
                {
                  color: "gray",
                  href: "/#example-link",
                  label: "tag3",
                },
              ]}
              title="Card 3"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
