import React, { useEffect } from "react";
import useServiceAuth from "hooks/useServiceAuth";
import Loading from "components/Loading";

const ServiceAuthRoute = ({ children }) => {
  const { isAuthenticated, isLoading, redirectToServiceauth } =
    useServiceAuth();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      (async () => {
        await redirectToServiceauth();
      })();
    }
  }, [isAuthenticated, isLoading, redirectToServiceauth]);

  return isAuthenticated ? children : <Loading />;
};

export default ServiceAuthRoute;
